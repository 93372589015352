import { CategoryType } from "api/CategoriesAPI/urls";
import { LabelListItemProfileLegacy } from "components/Profile/LabelListProfile/typing";
import { TranslationDataUnit } from "i18n/typing";
import CategoryModel from "models/categories/Category";
import CategorySelectableModel from "models/categories/CategorySelectable";
import SkillModel from "models/skills/Skill";
import CategoriesServiceSingleton from "services/categories/CategoriesService";
import TranslationsServiceSingleton from "services/translations/TranslationsService";
import { ObjectUtils } from "./ObjectUtils";

export namespace FeatureUtils {
	export interface PersonalSkills {
		personalSkills: CategorySelectableModel[];
	}

	export interface TechnicalSkills {
		businessSkills: CategorySelectableModel[];
		accountantSkills: CategorySelectableModel[];
		designAndProductSkills: CategorySelectableModel[];
		engineeringAndArchitectureSkills: CategorySelectableModel[];
		investmentSkills: CategorySelectableModel[];
		itSkills: CategorySelectableModel[];
		legalSkills: CategorySelectableModel[];
		salesAndMarketingSkills: CategorySelectableModel[];
		[key: string]: CategorySelectableModel[];
	}

	type TranslatedSkills = PersonalSkills & TechnicalSkills;

	const getTranslatedUnitSkills = (
		dataUnit: TranslationDataUnit,
		categories: CategoryModel[],
	): CategorySelectableModel[] => {
		const translatedCategories: CategorySelectableModel[] = [];
		const { items } = dataUnit;

		for (const key in items) {
			if (Object.prototype.hasOwnProperty.call(items, key)) {
				const selectedCategory = categories.find((category) => category.key === key);

				if (selectedCategory) {
					selectedCategory.setText(items[key]);

					translatedCategories.push(CategorySelectableModel.createFromCommonCategory(selectedCategory));
				}
			}
		}

		return translatedCategories;
	};

	export const getTranslatedSkills = (): TranslatedSkills => {
		const {
			personalSkills,
			businessSkills,
			accountantSkills,
			//TODO Fix this typo
			designAndProductoSkills,
			engineeringAndArchitectureSkills,
			investmentSkills,
			itSkills,
			legalSkills,
			salesAndMarketingSkills,
		} = TranslationsServiceSingleton.getSplitFeatureSkillsTranslations();
		const categories = CategoriesServiceSingleton.getList().filterByCategoryType(CategoryType.SKILL) as CategoryModel[];

		return {
			personalSkills: getTranslatedUnitSkills(personalSkills, categories),
			businessSkills: getTranslatedUnitSkills(businessSkills, categories),
			accountantSkills: getTranslatedUnitSkills(accountantSkills, categories),
			designAndProductSkills: getTranslatedUnitSkills(designAndProductoSkills, categories),
			engineeringAndArchitectureSkills: getTranslatedUnitSkills(engineeringAndArchitectureSkills, categories),
			investmentSkills: getTranslatedUnitSkills(investmentSkills, categories),
			itSkills: getTranslatedUnitSkills(itSkills, categories),
			legalSkills: getTranslatedUnitSkills(legalSkills, categories),
			salesAndMarketingSkills: getTranslatedUnitSkills(salesAndMarketingSkills, categories),
		};
	};

	export const getSelectedPersonalSkills = (personalLabels: CategorySelectableModel[]): SkillModel[] => {
		return personalLabels
			.filter((item) => item.selected)
			.map((item) => ({
				id: Number(item.id),
				name: item.key,
				porcentage: 25,
				color: "blue",
				text: item.text,
			}));
	};

	export const getSelectedTechnicalSkills = (technicalLabels: TechnicalSkills): SkillModel[] => {
		let idSelectedCategories: SkillModel[] = [];

		for (const key in technicalLabels) {
			if (Object.prototype.hasOwnProperty.call(technicalLabels, key)) {
				const categories = technicalLabels[key] as CategorySelectableModel[];
				const skills: SkillModel[] = categories
					.filter((category) => category.selected)
					.map((category) => ({
						id: Number(category.id),
						name: category.key,
						porcentage: 25,
						color: "blue",
						text: category.text,
					}));

				idSelectedCategories = [...idSelectedCategories, ...skills];
			}
		}

		return idSelectedCategories;
	};

	export function convertObjectPropsToLabelListItem(
		objectsProps: ObjectUtils.ObjectProps[],
		preSelecteds?: LabelListItemProfileLegacy[],
	): LabelListItemProfileLegacy[] {
		return objectsProps.map((item) => ({
			id: item.value,
			idCategory: item.value,
			name: item.text,
			selected:
				preSelecteds &&
				Boolean(preSelecteds.find((preSelected) => preSelected.idCategory === item.value && preSelected.selected)),
		}));
	}
}
