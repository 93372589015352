import { FilterSearcherItemType } from "components/Searcher/FilterSearcher/typing";
import { getSystemConstantsLEGACY } from "i18n";
import { ObjectUtils } from "utils/ObjectUtils";

export const FEATURES = "features";
export const DEFAULT_SKILL_VALUE = 25;
export const KEYWORD_SCHOOLS = "schools";
export const KEYWORD_MARKETS = "markets";
export const KEYWORD_SECTORS = "sectors";
export const KEYWORD_CAREER_MODALITY = "careerModalities";
export const KEYWORD_CAREER_TYPE = "careerTypes";
export const KEYWORD_REGION = "regions";
export const KEYWORD_INTRAPRENEURSHIP = "intrapreneurships";
export const INTRAPRENEURSHIP = "INTRAPRENEURSHIP";
export const SYSTEM_FEATURES = getSystemConstantsLEGACY().then((response) => {
	return response && !response.message
		? response.find((item: any) => item.field === FEATURES).translations[0].data
		: [];
});

export enum Area {
	BUSINESS = "businessSkills",
	SALES_AND_MARKETING = "salesAndMarketingSkills",
	INVESTMENT = "investmentSkills",
	DESIGN_AND_PRODUCT = "designAndProductoSkills",
	IT = "itSkills",
	LEGAL = "legalSkills",
	ACCOUNTANT = "accountantSkills",
	ENGINEERING_AND_ARCHITECTURAL = "engineeringAndArchitectureSkills",
	PERSONAL = "personalSkills",
}

export interface AreaSkill {
	id?: number;
	name: string;
}

export interface AreaSkills {
	area: string;
	skills: AreaSkill[];
}

export interface CommonTranslationPromiseProps {
	text: string;
	value: string;
}

export const AreaPromise = SYSTEM_FEATURES.then((features) => {
	const areaArray = features.area ? ObjectUtils.convertObjectToArray(features.area.items) : [];
	return areaArray.sort((area, nextArea) => Number(area.text > nextArea.text));
});

export const RolesPromise = SYSTEM_FEATURES.then((features) => {
	const rolesArray = features.roles ? ObjectUtils.convertObjectToArray(features.roles.items) : [];
	return rolesArray.sort((rol, nextRol) => Number(rol.text > nextRol.text));
});

export const TalentTypePromise = SYSTEM_FEATURES.then((features) => {
	const talentTypeArray = features.talentType ? ObjectUtils.convertObjectToArray(features.talentType.items) : [];
	return talentTypeArray.sort((talentType, nextTalentType) => Number(talentType.text > nextTalentType.text));
});

export const MarketplaceTypePromise = SYSTEM_FEATURES.then((features) => {
	const marketplaceTypeArray = features.marketplaceType
		? ObjectUtils.convertObjectToArray(features.marketplaceType.items)
		: [];
	return marketplaceTypeArray.sort((marketplaceType, nextMarketplaceType) =>
		Number(marketplaceType.text > nextMarketplaceType.text),
	);
});

export const MarketplaceTypeCheckBoxs = MarketplaceTypePromise.then((types) => {
	return types.map((type: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: type,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const SchoolPromise = SYSTEM_FEATURES.then((features) => {
	const schoolArray = features.school ? ObjectUtils.convertObjectToArray(features.school.items) : [];
	return schoolArray.sort((school, nextSchool) => Number(school.text > nextSchool.text));
});

export const SchoolCheckBoxs = SchoolPromise.then((schools) => {
	return schools.map((school: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: school,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const CareerTypePromise = SYSTEM_FEATURES.then((features) => {
	const careerTypeArray = features.careerType ? ObjectUtils.convertObjectToArray(features.careerType.items) : [];
	return careerTypeArray.sort((careerType, nextCareerType) => Number(careerType.text > nextCareerType.text));
});

export const CareerTypeCheckBoxs = CareerTypePromise.then((types) => {
	return types.map((type: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: type,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const CareerModalityPromise = SYSTEM_FEATURES.then((features) => {
	const careerModalityArray = features.careerModality
		? ObjectUtils.convertObjectToArray(features.careerModality.items)
		: [];
	return careerModalityArray.sort((careerModality, nextCareerModality) =>
		Number(careerModality.text > nextCareerModality.text),
	);
});

export const CareerModalityCheckBoxs = CareerModalityPromise.then((mods) => {
	return mods.map((mod: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: mod,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const MarketsPromise = SYSTEM_FEATURES.then((features) => {
	const marketArray = features.market ? ObjectUtils.convertObjectToArray(features.market.items) : [];
	return marketArray.sort((market, nextMarket) => Number(market.text > nextMarket.text));
});

export const MarketsCheckBoxs = MarketsPromise.then((markets) => {
	return markets.map((market: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: market,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const GenderPromise = SYSTEM_FEATURES.then((features) => {
	const genderArray = features.gender ? ObjectUtils.convertObjectToArray(features.gender.items) : [];
	return genderArray.sort((gender, nextGender) => Number(gender.text > nextGender.text));
});

export const AdviserSkillssPromise = SYSTEM_FEATURES.then((features) => {
	const adviserSkillsArray = features.adviserSkills
		? ObjectUtils.convertObjectToArray(features.adviserSkills.items)
		: [];
	return adviserSkillsArray.sort((adviserSkills, nextAdviserSkills) =>
		Number(adviserSkills.text > nextAdviserSkills.text),
	);
});

export const AdviserSkillssCheckBoxs = AdviserSkillssPromise.then((adviserSkills) => {
	return adviserSkills.map((skill: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: skill,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const SkillPersonalPromise = SYSTEM_FEATURES.then((features) => {
	const skillPersonalArray = features.personalSkills
		? ObjectUtils.convertObjectToArray(features.personalSkills.items)
		: [];
	return skillPersonalArray.sort((personalSkills, nextSkillTech) => Number(personalSkills.text > nextSkillTech.text));
});

export const SkillPersonalCheckBoxs = SkillPersonalPromise.then((skillPersonal) => {
	return skillPersonal.map((skill: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: skill,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const SkillTechnicalPromise = SYSTEM_FEATURES.then((features) => {
	const skillTechnicalArray = features.technicalSkills
		? ObjectUtils.convertObjectToArray(features.technicalSkills.items)
		: [];
	return skillTechnicalArray.sort((technicalSkills, nextSkillTech) =>
		Number(technicalSkills.text > nextSkillTech.text),
	);
});

export const SkillTechnicalCheckBoxs = SkillTechnicalPromise.then((skillTechnical) => {
	return skillTechnical.map((skill: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: skill,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const SkillBusinessSkillsPromise = SYSTEM_FEATURES.then((features) => {
	const skills = features.businessSkills ? ObjectUtils.convertObjectToArray(features.businessSkills.items) : [];
	return skills.sort((prevSkill, nextSkill) => Number(prevSkill.text > nextSkill.text));
});

export const SkillBusinessSkillsCheckBoxs = SkillBusinessSkillsPromise.then((skills) => {
	return skills.map((skill: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: skill,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const SkillSalesAndMarketingSkillsPromise = SYSTEM_FEATURES.then((features) => {
	const skills = features.salesAndMarketingSkills
		? ObjectUtils.convertObjectToArray(features.salesAndMarketingSkills.items)
		: [];
	return skills.sort((prevSkill, nextSkill) => Number(prevSkill.text > nextSkill.text));
});

export const SkillSalesAndMarketingSkillsCheckBoxs = SkillSalesAndMarketingSkillsPromise.then((skills) => {
	return skills.map((skill: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: skill,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const SkillInvestmentSkillsPromise = SYSTEM_FEATURES.then((features) => {
	const skills = features.investmentSkills ? ObjectUtils.convertObjectToArray(features.investmentSkills.items) : [];
	return skills.sort((prevSkill, nextSkill) => Number(prevSkill.text > nextSkill.text));
});

export const SkillInvestmentSkillsCheckBoxs = SkillInvestmentSkillsPromise.then((skills) => {
	return skills.map((skill: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: skill,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const SkillDesignAndProductoSkillsPromise = SYSTEM_FEATURES.then((features) => {
	const skills = features.designAndProductoSkills
		? ObjectUtils.convertObjectToArray(features.designAndProductoSkills.items)
		: [];
	return skills.sort((prevSkill, nextSkill) => Number(prevSkill.text > nextSkill.text));
});

export const SkillDesignAndProductoSkillsCheckBoxs = SkillDesignAndProductoSkillsPromise.then((skills) => {
	return skills.map((skill: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: skill,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const SkillItSkillsPromise = SYSTEM_FEATURES.then((features) => {
	const skills = features.itSkills ? ObjectUtils.convertObjectToArray(features.itSkills.items) : [];
	return skills.sort((prevSkill, nextSkill) => Number(prevSkill.text > nextSkill.text));
});

export const SkillItSkillsCheckBoxs = SkillItSkillsPromise.then((skills) => {
	return skills.map((skill: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: skill,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const SkillLegalSkillsPromise = SYSTEM_FEATURES.then((features) => {
	const skills = features.legalSkills ? ObjectUtils.convertObjectToArray(features.legalSkills.items) : [];
	return skills.sort((prevSkill, nextSkill) => Number(prevSkill.text > nextSkill.text));
});

export const SkillLegalSkillsCheckBoxs = SkillLegalSkillsPromise.then((skills) => {
	return skills.map((skill: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: skill,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const SkillAccountantSkillsPromise = SYSTEM_FEATURES.then((features) => {
	const skills = features.accountantSkills ? ObjectUtils.convertObjectToArray(features.accountantSkills.items) : [];
	return skills.sort((prevSkill, nextSkill) => Number(prevSkill.text > nextSkill.text));
});

export const SkillAccountantSkillsCheckBoxs = SkillAccountantSkillsPromise.then((skills) => {
	return skills.map((skill: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: skill,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const SkillEngineeringAndArchitectureSkillsPromise = SYSTEM_FEATURES.then((features) => {
	const skills = features.engineeringAndArchitectureSkills
		? ObjectUtils.convertObjectToArray(features.engineeringAndArchitectureSkills.items)
		: [];
	return skills.sort((prevSkill, nextSkill) => Number(prevSkill.text > nextSkill.text));
});

export const SkillEngineeringAndArchitectureSkillsCheckBoxs = SkillEngineeringAndArchitectureSkillsPromise.then(
	(skills) => {
		return skills.map((skill: ObjectUtils.ObjectProps) => {
			return {
				title: "",
				value: skill,
				type: FilterSearcherItemType.CHECKBOX,
			};
		});
	},
);

export const SkillAdviserBusiness = SYSTEM_FEATURES.then((features) => {
	const categoriesBusiness = features.categoriesBusiness
		? ObjectUtils.convertObjectToArray(features.categoriesBusiness.items)
		: [];
	return categoriesBusiness.sort((skill, nextSkill) => Number(skill.text > nextSkill.text));
});

export const SkillAdviserBusinessCheckBoxs = SkillAdviserBusiness.then((skills) => {
	return skills.map((skill: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: skill,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const SkillAdviserSalesAndMarketing = SYSTEM_FEATURES.then((features) => {
	const categoriesSalesAndMarketing = features.categoriesSalesAndMarketing
		? ObjectUtils.convertObjectToArray(features.categoriesSalesAndMarketing.items)
		: [];
	return categoriesSalesAndMarketing.sort((skill, nextSkill) => Number(skill.text > nextSkill.text));
});

export const SkillAdviserSalesAndMarketingCheckBoxs = SkillAdviserSalesAndMarketing.then((skills) => {
	return skills.map((skill: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: skill,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const SkillAdviserInvestment = SYSTEM_FEATURES.then((features) => {
	const categoriesInvestment = features.categoriesInvestment
		? ObjectUtils.convertObjectToArray(features.categoriesInvestment.items)
		: [];
	return categoriesInvestment.sort((skill, nextSkill) => Number(skill.text > nextSkill.text));
});

export const SkillAdviserInvestmentCheckBoxs = SkillAdviserInvestment.then((skills) => {
	return skills.map((skill: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: skill,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const SkillAdviserDesignAndProduct = SYSTEM_FEATURES.then((features) => {
	const categoriesDesingAndProduct = features.categoriesDesingAndProduct
		? ObjectUtils.convertObjectToArray(features.categoriesDesingAndProduct.items)
		: [];
	return categoriesDesingAndProduct.sort((skill, nextSkill) => Number(skill.text > nextSkill.text));
});

export const SkillAdviserDesignAndProductCheckBoxs = SkillAdviserDesignAndProduct.then((skills) => {
	return skills.map((skill: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: skill,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const SkillAdviserIT = SYSTEM_FEATURES.then((features) => {
	const categoriesIT = features.categoriesIT ? ObjectUtils.convertObjectToArray(features.categoriesIT.items) : [];
	return categoriesIT.sort((skill, nextSkill) => Number(skill.text > nextSkill.text));
});

export const SkillAdviserITCheckBoxs = SkillAdviserIT.then((skills) => {
	return skills.map((skill: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: skill,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const SkillAdviserLegal = SYSTEM_FEATURES.then((features) => {
	const categoriesLegal = features.categoriesLegal
		? ObjectUtils.convertObjectToArray(features.categoriesLegal.items)
		: [];
	return categoriesLegal.sort((skill, nextSkill) => Number(skill.text > nextSkill.text));
});

export const SkillAdviserLegalCheckBoxs = SkillAdviserLegal.then((skills) => {
	return skills.map((skill: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: skill,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const SkillAdviserAccountant = SYSTEM_FEATURES.then((features) => {
	const categoriesAccountant = features.categoriesAccountant
		? ObjectUtils.convertObjectToArray(features.categoriesAccountant.items)
		: [];
	return categoriesAccountant.sort((skill, nextSkill) => Number(skill.text > nextSkill.text));
});

export const SkillAdviserAccountantCheckBoxs = SkillAdviserAccountant.then((skills) => {
	return skills.map((skill: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: skill,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const SkillAdviserEngineeringAndArchitecture = SYSTEM_FEATURES.then((features) => {
	const categoriesEngineeringAndArchitecture = features.categoriesEngineeringAndArchitecture
		? ObjectUtils.convertObjectToArray(features.categoriesEngineeringAndArchitecture.items)
		: [];
	return categoriesEngineeringAndArchitecture.sort((skill, nextSkill) => Number(skill.text > nextSkill.text));
});

export const SkillAdviserEngineeringAndArchitectureCheckBoxs = SkillAdviserEngineeringAndArchitecture.then((skills) => {
	return skills.map((skill: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: skill,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});

export const SkillAdviserMedicineAndPsychology = SYSTEM_FEATURES.then((features) => {
	const categoriesMedicineAndPsychology = features.categoriesMedicineAndPsychology
		? ObjectUtils.convertObjectToArray(features.categoriesMedicineAndPsychology.items)
		: [];
	return categoriesMedicineAndPsychology.sort((skill, nextSkill) => Number(skill.text > nextSkill.text));
});

export const SkillAdviserMedicineAndPsychologyCheckBoxs = SkillAdviserMedicineAndPsychology.then((skills) => {
	return skills.map((skill: ObjectUtils.ObjectProps) => {
		return {
			title: "",
			value: skill,
			type: FilterSearcherItemType.CHECKBOX,
		};
	});
});
